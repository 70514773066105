import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../apiAccess";
import fileDownload from 'js-file-download';


export const getDataBaseSource = createAsyncThunk('dataBaseSlice', async (payload, thunkAPI) => {
  const [sourceName, page = 2, district_id = "all", block_id = "all", village_id="all"] = payload;
  let params = {};
  if (sourceName === 'farmer_db' || sourceName === 'chc_db') Object.assign(params, {page}, {district_id, block_id, village_id});
  try {
    const response = await axiosClient.get(`/annexure/${sourceName}/`, {params});
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getDataBaseCounts = createAsyncThunk('dataBaseCount', async (payload, thunkAPI) => {
  const [ district_id = "all", block_id = "all", village_id="all"] = payload;
  let params = {
    district_id:district_id,
    block_id: block_id,
    village_id: village_id,
  };
try {
    const response = await axiosClient.get("/annexure/db_count/", {params});
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});


export const getDataBaseDownload = createAsyncThunk(
  'dataBaseDownload',
  async (payload, thunkAPI) => {
    const [
      sourceName,
      district_id = "all",
      block_id = "all",
      village_id = "all",
      download_type ="excel"
    ] = payload;

    let source;
    if (sourceName === "farmer_db") {
      source = 'download_farmer_db';
    } else {
      source = 'download_chc_db';
    }


    let params = {};
    if (source === 'download_farmer_db' || source === 'download_chc_db') {
      Object.assign(params, { district_id, block_id, village_id, download_type });
    }

    try {
      const response = await axiosClient.get(`/annexure/${source}/`, {
        params,
        responseType: 'blob'
      });

      const fileName = download_type === 'excel' ? 'report.xlsx' : 'report.pdf';

      fileDownload(response.data, fileName);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



const dataBaseSlice = createSlice({
    name: 'dataBaseSlice',
    initialState: {
      loading: false,
      error: null,
      data: null,
      download_data: null,
      dataBaseCounts: {}
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getDataBaseSource.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataBaseSource.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getDataBaseSource.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(getDataBaseDownload.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataBaseDownload.fulfilled, (state, action) => {
          state.loading = false;
          state.download_data = action.payload;
        })
        .addCase(getDataBaseDownload.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(getDataBaseCounts.pending, (state) => {
          return { ...state, countLoading: true }
        })
        .addCase(getDataBaseCounts.fulfilled, (state, action) => {
          return { ...state, countLoading: false, getDataBaseCounts: action?.payload }
        })
        .addCase(getDataBaseCounts.rejected, (state, action) => {
          return { ...state, countLoading: false }
        });
    },
  });
  
  export default dataBaseSlice.reducer;