import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../apiAccess";

// Define an async thunk action
export const getReportData = createAsyncThunk('getReportData', async (payload, thunkAPI) => {
    let [district_id, date] = payload;
    if (!district_id) return console.error('No District ID found'); 
    if (!date || date.split('-').length !== 3) return console.error('No Date Found');
    try {
      const response = await axiosClient.get(`/annexure/new_report/`, {
        params: {district_id, date},
      });
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
});

export const getAllDistricts = createAsyncThunk(
  "report/getAllDistrictsAction",
  async (payload, thunkAPI) => {
    const [source = "web"] = payload;
    try {
      const response = await axiosClient.get(`/annexure/getAllDistricts/`, {
        params: { source },
      })
      const districtList = response?.data;
      const staticData = [{
        district_id: 'all',
        district_name: "All Districts"
      }]
      return [...staticData, ...districtList]
    } catch (error) {
      throw thunkAPI.rejectWithValue(error?.message);
    }
  }
);

const getUserSlice = createSlice({
  name: 'newUser',
  initialState: {
    data: null,
    loading: 'idle',
    isLoading: false,
    error: null,
    districtList: []
  },
  reducers: {
    clearStoreDataAction(state) {//clear the list on goback
      return { ...state, data: null, districtList: [], isLoading: false }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getReportData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getReportData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload;
      })
      .addCase(getAllDistricts.pending, (state) => {
        return { ...state, isLoading: true }
      })
      .addCase(getAllDistricts.fulfilled, (state, action) => {
        return { ...state, isLoading: false, districtList: action?.payload }
      })
      .addCase(getAllDistricts.rejected, (state, action) => {
        return { ...state, isLoading: false }
      });
  },
});
export const { clearStoreDataAction } = getUserSlice.actions;

export default getUserSlice.reducer;
