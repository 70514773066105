import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../redux/slice/getEventSlice";
import EmptyState from "../components/DataTableEmptyState";
import filterIcon from "../assets/filter.svg";
import { FilterDropdown } from "../components/";
import { TimeFormat } from "./TimeFormat";
import EventDetails from "./EventDetails";
import { fetchData, getAllDistricts } from "../redux/slice/getUserSlice";
import {
  getFilteredBlockList,
  getFilteredVillageList,
} from "../redux/slice/marketSlice";
import { format } from "date-fns";
import { baseURL } from "../apiAccess";

const EventLandingPage = (props) => {
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState(null);
  const [villageList, setVillageList] = useState(null);

  const dispatch = useDispatch();
  const eventData = useSelector((state) => state?.getEventSlice?.events);
  const isEventLoading = useSelector((state) => state?.getEventSlice?.status);
  const allDistricts = useSelector(
    (state) => state?.getUserSlice?.districtList
  );
  const getVillage = useSelector((state) => state?.marketSlice?.villageList);
  const getBlocks = useSelector((state) => state?.marketSlice?.blockList);
  const [events, setEvents] = useState([]);

  const userDataJSON = localStorage.getItem("userData");
  const userData = JSON.parse(userDataJSON);

  useEffect(() => {
    if (eventData && eventData.length > 0) {
      setEvents(eventData);
    }
  }, [eventData]);
  const [sortingOrder, setSortingOrder] = useState("ascending");
  // Sort the events based on event_date and the selected sorting order
  const sortEvents = (order) => {
    const sortedEvents = [...events].sort((a, b) => {
      const dateA = new Date(a.event_date);
      const dateB = new Date(b.event_date);
      if (order === "ascending") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setEvents(sortedEvents);
  };

  const handleSortingChange = (event) => {
    const order = event.target.value;
    setSortingOrder(order);
    sortEvents(order);
  };
  //district filter
  const handleDistrictChange = (districtId, districtName) => {
    if (districtId === "all") {
      setEvents(eventData);
    } else {
      const filteredEvents = eventData.filter((event) => {
        return event.district_id === districtId;
      });
      setEvents(filteredEvents);
    }
    dispatch(getFilteredBlockList(districtId));
  };

  //block filter
  const handleBlockChange = (blockId) => {
    if (blockId === "") {
      setEvents(eventData);
    } else {
      const filteredEvents = eventData.filter((event) => {
        return event.block_id === blockId;
      });
      setEvents(filteredEvents);
    }
    dispatch(getFilteredVillageList(blockId));
  };

  //village filter
  const handleVillageChange = (villageId) => {
    if (villageId === "") {
      setEvents(eventData);
    } else {
      const filteredEvents = eventData.filter((event) => {
        return event.village_code === villageId;
      });
      setEvents(filteredEvents);
    }
  };

  const openOffcanvas = (eventData) => {
    setOffcanvasOpen(true);
    setSelectedEvent(eventData);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  useEffect(() => {
    dispatch(fetchEvents());
    dispatch(getAllDistricts([]));
  }, [dispatch]);

  useEffect(() => {
    if (allDistricts) {
      setDistrictList(allDistricts);
    }
    setBlockList(getBlocks);
    setVillageList(getVillage);
  }, [allDistricts, getBlocks, getVillage]);

  return (
    <>
      <div className="container mx-4 mt-2 pt-3">
        <div className="row">
          <div className="col-lg-12 col-sm-6 col-md-12 d-flex justify-content-between align-items-center">
            <div>
              <h5>Events</h5>
            </div>
            <div onClick={props.toggleComponents}>
              {userData?.is_admin ? (
                <button type="button" className="btn btn-success">
                  + Create Event
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-end">
          <div className="col-md-2 ms-3">
            <span>Sort / Filter By</span>
            <span>
              <img
                className="ms-1 me-2"
                src={filterIcon}
                alt="filter"
                style={{ height: "1.25em" }}
              />
            </span>
          </div>
          <div className="col-md-2">
            <select
              className="form-select filter-dropdown mx-1"
              id="sortingOrder"
              value={sortingOrder}
              onChange={handleSortingChange}
            >
              <option value="ascending">&#x2191; Date Ascending</option>
              <option value="descending">&#x2193;Date Descending</option>
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              id="district"
              onChange={(e) => {
                const selectedDistrictId = e.target.value;
                const selectedDistrict =
                  e.target.options[e.target.selectedIndex].text; // Get the selected district name
                handleDistrictChange(selectedDistrictId, selectedDistrict); // Pass both ID and name
              }}
            >
              {districtList &&
                districtList.map((district) => (
                  <option
                    key={district.district_id}
                    value={district.district_id}
                  >
                    {district.district_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              id="block"
              required
              onChange={(e) => {
                const selectedBlockId = e.target.value;
                const selectedBlock =
                  e.target.options[e.target.selectedIndex].text; // Get the selected block name
                handleBlockChange(selectedBlockId, selectedBlock); // Pass both ID and name
              }}
            >
              <option value="">All Blocks</option>
              {blockList &&
                blockList?.map((block) => (
                  <option key={block.block_id} value={block.block_id}>
                    {block?.block_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-2">
            <select
              className="form-select"
              id="village"
              required
              onChange={(e) => {
                const selectedVillageId = e.target.value;
                handleVillageChange(selectedVillageId);
              }}
            >
              <option value="">All Villages</option>
              {villageList &&
                villageList?.map((village) => (
                  <option key={village?.village_id} value={village?.village_id}>
                    {village?.village_name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container pt-2">
        <div className="row mt-5">
          <div className="col-md-12 col-lg-12">
            {isEventLoading === "loading" && <p>Loading.......</p>}
            {events.length === 0 ? (
              <p style={{ textAlign: "center" }}>No events found</p>
            ) : (
              events.map((items, index) => (
                <div className="col-md-12 mt-2 card-containers" key={index}>
                  <div className="card  card_height">
                    <div className="row ">
                      <div className="col-md-4 col-sm-6">
                        {items?.files.length > 0 && (
                          <div>
                            <img
                              key={items.files[0].file_id}
                              src={`${baseURL}${items.files[0].event_file}`}
                              alt={`Image ${items.files[0].file_id}`}
                              height="200vh"
                              width="100%"
                              className="img_responsive"
                            />
                          </div>
                        )}
                      </div>

                      <div className="col-md-8 col-sm-6">
                        <div className="card-body">
                          <h3 className="card-title">{items?.event_name}</h3>
                          <p className="card-text">
                            {items?.event_description}
                          </p>
                          <p className="card-text">
                            <strong className="date_font">
                              Date and Time:&nbsp; &nbsp; &nbsp;
                            </strong>
                            <span className="time_font">
                              {format(
                                new Date(items?.event_date),
                                "dd/MM/yyyy"
                              )}{" "}
                              at {TimeFormat(items?.event_time)}
                            </span>
                          </p>
                          <p className="card-text">
                            <strong className="date_font">
                              Venue:&nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                            </strong>
                            {items && (
                              <span className="time_font">
                                {items.district_name &&
                                  `${items.district_name}`}{" "}
                                {items.block_name && `, ${items.block_name}`}
                                {items.village_name &&
                                  `, ${items.village_name}`}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* offcanvas */}

                    <div className="row">
                      <div className="col-md-11 d-flex justify-content-end">
                        <p
                          className="view_details"
                          onClick={() => openOffcanvas(items)}
                        >
                          View Details
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        className={`offcanvas offcanvas-bottom ${
                          isOffcanvasOpen ? "show" : ""
                        }`}
                        id="offcanvasBottom"
                        aria-labelledby="offcanvasBottomLabel"
                      >
                        <div className="offcanvas-header d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn-close text-reset"
                            onClick={closeOffcanvas}
                            aria-label="Close"
                          />
                        </div>
                        <div className="offcanvas-body small">
                          {/* Content of the offcanvas */}
                          {selectedEvent && (
                            <EventDetails event={selectedEvent} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventLandingPage;
