import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DistrictData } from "../district_data";
import { SummaryData } from "../summary_data";
import { getReportData, getAllDistricts } from "../redux/slice/getUserSlice";
import { Navbar, DistrictDropdown, Welcome } from "../components/";
import {
  getDownloadFile,
  getSummaryDownloadFile,
  summaryReportGet,
} from "../redux/slice/summaryReportSlice";
import { Link } from "react-router-dom";
import img1 from "../assets/Group 1000002930.png";
import img2 from "../assets/Group 1000002932.png";
import filterIcon from "../assets/filter.svg";
import { format } from "date-fns";

const Home = () => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("userData"));
  const report = useSelector((state) => state?.getUserSlice);
  const summary = useSelector((state) => state?.summaryReportSlice?.summary);
  // const is_user_state_head = user?.is_state_head || user?.is_admin;
  const state_head_or_admin = user?.is_state_head === 1 || user?.is_admin;
  const aae_and_not_admin = user?.is_state_head === 0 && !user?.is_admin;

  const formatDate = (d) => d.toISOString().split("T")[0];

  const [currentDistrict, setCurrentDistrict] = useState(
    state_head_or_admin ? "all" : user?.district_id
  );
  // const [download_type, setDownload_type] = useState();

  const [endDate, setEndDate] = useState(new Date());
  const [reportData, setReportData] = useState({});
  const [highlights, setHighlights] = useState({
    last_updated: "N/A",
    farmers: 0,
    area: 0,
  });

  // let summary_data = report?.data || {};
  const district_list = report?.districtList?.map((d) => ({
    value: d.district_id,
    name: d.district_name,
  }));

  useEffect(() => {
    setCurrentDistrict(state_head_or_admin ? "all" : currentDistrict);
    dispatch(getAllDistricts([]));
  }, []);

  useEffect(() => {
    setReportData(report?.data || {});
    if (report && report.data) {
      calculateHighlights({
        last_updated: getLatestUpdated(report?.data),
        farmers: report?.data?.decomposer?.farmers_benifitted || 0,
        area:
          report?.data?.paddy_harvesting
            ?.paddy_area_harvested_till_date_acres || 0,
      });
    }
  }, [report]);

  useEffect(() => {
    if (!state_head_or_admin)
      dispatch(getReportData([currentDistrict, formatDate(endDate)]));
  }, [currentDistrict, endDate]);

  useEffect(() => {
    if (state_head_or_admin) dispatch(summaryReportGet(formatDate(endDate)));
  }, [endDate]);

  function getLatestUpdated(data) {
    if (!data) return "N/A";
    let latest = null;
    const isLater = (current, next) => new Date(next) > new Date(current);
    const traverse = (obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === "last_updated_on") {
            if (obj[key] && isLater(latest, obj[key])) {
              latest = obj[key];
            }
          } else if (typeof obj[key] === "object") {
            traverse(obj[key]);
          }
        }
      }
    };
    traverse(data);
    if (!latest) latest = data.saved_on_date;
    return format(new Date(latest), "dd/MM/yyyy");
  }

  function calculateHighlights(params) {
    setHighlights({ ...highlights, ...params });
  }

  function handleDistrictChange(e) {
    setCurrentDistrict(e.target.value);
  }

  function handleDownload(type) {
    const download_type = type
    let data = {};
    if (state_head_or_admin) {
      if (currentDistrict !== "all") {
        Object.entries(summary).forEach(([section, value]) => {
          data[section] = value.filter(
            (v) => parseInt(v.district_id) === parseInt(currentDistrict)
          );
        });
      } else {
        data = Object.assign(data, summary, { download_type: download_type });
      }
      data.saved_on_date = formatDate(endDate);
      dispatch(getSummaryDownloadFile(data));
    } else {
      data = Object.assign(data, report?.data, {
        district_id: currentDistrict,
        download_type: download_type,
      });
      data.saved_on_date = formatDate(endDate);
      dispatch(getDownloadFile(data));
    }
  }

  
  function changeDate(e) {
    if (!e.target.value) return;
    setEndDate(new Date(e.target.value));
  }

  return (
    <>
      <Navbar active="overview" />
      <div className="container mt-3">
        <Welcome />
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-2">
            <div className="card">
              <div className="d-flex align-items-center">
                <img className="px-4 my-1" src={img1} alt="test"></img>
                <div>
                  <h6>Farmers Benefited</h6>
                  <small>{highlights.farmers}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <div className="d-flex align-items-center">
                <img className="px-4 mb-3" src={img2} alt="test"></img>
                <div>
                  <h6>Total Area Covered</h6>
                  <small>{highlights.area}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row d-flex justify-content-between mb-3">
          <div className="col-lg-3 col-sm-12 mb-2">
            <h4 className="mb-0">Cumulative Status</h4>
            <div>
              Last Updated on <strong>{highlights.last_updated}</strong>
            </div>
          </div>
          <div className="col-lg-9 col-sm-12 d-flex align-items-center justify-content-end">
            <div className="mx-2">
              <span className="me-2">Filters</span>
              <img
                className=""
                src={filterIcon}
                alt="filter"
                style={{ height: "1.5em" }}
              />
            </div>
            <div className="mx-1">
              <input
                type="date"
                max={formatDate(new Date())}
                value={formatDate(endDate)}
                onChange={changeDate}
                className="form-control"
                required
              />
            </div>
            {state_head_or_admin ? (
              <div className="mx-1">
                <DistrictDropdown
                  callback={handleDistrictChange}
                  data={district_list}
                />
              </div>
            ) : (
              ""
            )}
            {
              <div className="mx-1">
                <div className="dropdown">
                  <button
                    className="btn btn-success btn-project dropdown-toggle"
                    type="button"
                    id="downloadReportDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    &#x2B73; Download Report
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="downloadReportDropdown"
                  >
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={(e) => {
                          handleDownload("excel"); // Trigger the download logic
                        }}
                      >
                        Excel Report
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        onClick={(e) => {
                          handleDownload("pdf"); // Trigger the download logic
                        }}
                      >
                        PDF Report
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            }
            {aae_and_not_admin ? (
              <div className="ms-1">
                <Link
                  to="/update"
                  className="btn btn-success btn-project"
                  title="Update Form"
                >
                  Update Details
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {state_head_or_admin ? (
          <SummaryData
            summary={summary}
            district_filter={currentDistrict}
            bubble={calculateHighlights}
          />
        ) : (
          <DistrictData data={reportData} readOnly district_id={user?.id} />
        )}
      </div>
    </>
  );
};

export default Home;
