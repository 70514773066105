function Welcome() {
  const user = JSON.parse(localStorage.getItem("userData"));
  const state_head_or_admin = user?.is_state_head === 1 || user?.is_admin;

  return (
    <>
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-lg-6 col-sm-12">
      {state_head_or_admin ? (
          <h3>
            Welcome <strong>{user?.designation} {user?.state}</strong>
          </h3>) : (
             <h3>
             Welcome <strong>{user?.designation} {user?.district}</strong>
           </h3>
          )}        </div>
        <div className="col-lg-2 col-sm-12 text-end">
          {state_head_or_admin ? (
            <div>
              <small>State</small>
              <h4>{user?.state || ""}</h4>
            </div>
          ) : (
            <div>
              <small>District</small>
              <h4 data-id={user?.district_id}>{user?.district || ""}</h4>
            </div>
          )}
        </div>
      </div>
      <hr />
    </>
  );
}
export { Welcome };
