import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredBlockList,
  getFilteredVillageList,
} from "../redux/slice/marketSlice";
import DataTable from "react-data-table-component";
import {
  getDataBaseSource,
  getDataBaseCounts,
  getDataBaseDownload,
} from "../redux/slice/dataBaseSlice";
import { Navbar, TabGroup, FilterDropdown, Welcome } from "../components/";
import customStyles from "../components/DataTableCustomStyles";
import EmptyState from "../components/DataTableEmptyState";
import { dataBase_columns } from "../dataBase_data/columns";
import filterIcon from "../assets/filter.svg";
import { format } from "date-fns";
import { fetchData, getAllDistricts } from "../redux/slice/getUserSlice";

const Database = () => {
  const dispatch = useDispatch();

  const dataBase = useSelector((state) => state?.dataBaseSlice);
  const isDBLoading = useSelector((state) => state?.dataBaseSlice?.loading);
  const counts = dataBase?.getDataBaseCounts;
  const user = JSON.parse(localStorage?.getItem("userData"));
  const state_head_or_admin = user?.is_state_head === 1 || user?.is_admin;
  const [source, setSource] = useState("chc_db");
  const [dataSource, setDataSource] = useState("kys");
  const [page, setPage] = useState(1);

  // const [lock, setLock] = useState(false);
  const [filters, updateFilters] = useState({
    district: "all",
    block: "all",
    village: "all",
  });

  const allDistricts = useSelector(
    (state) => state?.getUserSlice?.districtList
  );

  useEffect(() => {
    dispatch(getAllDistricts([dataSource]));
  }, []);


  console.log("data", dataBase)
  const serverBlockList = useSelector((state) => state?.marketSlice.blockList);
  const isBlockListLoading = useSelector(
    (state) => state?.marketSlice?.loadingBlocks
  );

  const serverVillageList = useSelector(
    (state) => state?.marketSlice.villageList
  );
  const isVillageListLoading = useSelector(
    (state) => state?.marketSlice?.loadingVillages
  );

  // const totalPages = Math.ceil((counts?.farmer_count || 1) / 100);

  const formatText = (str) =>
    str
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ") || str;
  let districtList = [],
    villageList = [],
    blockList = [];
  allDistricts.forEach((row) => {
    districtList.push({
      name: formatText(row.district_name),
      value: row.district_name,
      id: row.district_id,
    });
    blockList.push({
      name: formatText(row.block_name),
      value: row.block_name,
      id: row.block_id,
    });
    villageList.push({
      name: formatText(row.village_name),
      value: row.village_name,
      id: row.village_code,
    });
  });

  // console.log("first one", districtList);

  useEffect(() => {
    dispatch(getDataBaseCounts(getDistrictId()));
    dispatch(getDataBaseSource([source, page, getDistrictId()]));
    if (!state_head_or_admin) {
      updateFilters({ ...filters, district: user.district });
      dispatch(getFilteredBlockList(user.district_id));
    }
  }, []);

  function getDistrictId() {
    const dist = allDistricts?.find(
      (obj) => obj.district_name === filters.district
    );
    // console.log("hello1", filters);
    return state_head_or_admin ? dist?.district_id : user?.district_id;
  }

  useEffect(() => {
    if (
      serverBlockList &&
      serverBlockList.length === 1 &&
      serverBlockList[0]?.block_name
    ) {
      updateFilters({ ...filters, block: serverBlockList[0].block_name });
      filterChanged("block", serverBlockList[0].block_name);
    }
  }, [serverBlockList]);

  function uniq(arr, key) {
    if (!arr || !arr.length || !key || !key.length) return [];
    return arr
      .filter((a) => a[key])
      .reduce((unique, item) => {
        if (!unique.some((u) => u[key] === item[key])) unique.push(item);
        return unique;
      }, []);
  }

  let lastUpdated = () => {
    let date = "N/A";

    if (!dataBase?.data || !Array.isArray(dataBase.data)) {
      return date; // Return 'N/A' if dataBase.data is null, undefined, or not an array
    }

    let latest = dataBase?.data
      .map((d) => d?.modified_on)
      .filter(Boolean) // Filter out null or undefined values
      .reduce((a, b) => (a > b ? a : b), null);

    return latest ? format(new Date(latest), "dd/MM/yyyy") : date;
  };

  const sources = [
    {
      name: "farmer_db",
      label: "Farmer",
      amount: counts?.farmer_count || 0,
    },
    {
      name: "chc_db",
      label: "CHC",
      amount: counts?.chc_count || 0,
    },
    // {
    //     name: 'industry_db',
    //     label: 'Industry',
    //     amount: counts?.industry_count || 0
    // },
    // {
    //     name: 'third_party_db',
    //     label: 'Third Party',
    //     amount: counts?.third_party_count || 0
    // }
  ];
  function changeSource(next) {
    dispatch(getDataBaseSource([next.name, page, getDistrictId()]));
    setSource(next.name);
    resetFilters();
  }

  function resetFilters() {
    updateFilters({
      district: state_head_or_admin ? "all" : user?.district,
      block:
        serverBlockList?.length === 1 ? serverBlockList[0].block_name : "all",
      village: "all",
    });
    // updateFilters({
    //     district: 'all',
    //     block: 'all',
    //     village: 'all'
    // });
  }

  const isEqual = (a, b) =>
    typeof a === "string" && typeof b === "string"
      ? a.toLowerCase() === b.toLowerCase()
      : false;
  const exists = (item) => item && item !== null && item.length;

  const filteredData = (data) => {
    if (!data || !data.length) return [];
    return data?.filter((item) => {
      let show = true;
      if (
        filters.district !== "all" &&
        (!exists(item.district_name) ||
          !isEqual(item.district_name, filters.district))
      )
        show = false;
      if (
        filters.village !== "all" &&
        (!exists(item.village_name) ||
          !isEqual(item.village_name, filters.village))
      )
        show = false;
      if (
        filters.block !== "all" &&
        (!exists(item.block_name) || !isEqual(item.block_name, filters.block))
      )
        show = false;
      return show;
    });
  };

  function filterChanged(name, value) {
    var next = { [name]: value };

    if (name === "district") {
      Object.assign(next, { block: "all", village: "all" });
      updateFilters(next);

      const id = districtList.find((d) => d.value === value)?.id;
      if (id) {
        dispatch(getFilteredBlockList(id));
      }
    } else if (name === "block") {
      Object.assign(next, { village: "all", district: filters?.district });
      updateFilters(next);

      const id = whichBlockList().find((b) => b.value === value)?.id;
      if (id) {
        dispatch(getFilteredVillageList(id));
      }
    } else if (name === "village") {
      Object.assign(next, {
        block: filters?.block,
        district: filters?.district,
      });

      updateFilters(next);
    }
  }

  useEffect(() => {
    const district_Id =
      districtList.find((d) => d.value === filters.district)?.id || "all";
    const blockId =
      whichBlockList().find((b) => b.value === filters.block)?.id || "all";
    const villageId =
      whichVillageList().find((v) => v.value === filters.village)?.id || "all";

    console.log("Filters changed:", { district_Id, blockId, villageId });

    dispatch(getDataBaseSource([source, page, district_Id, blockId, villageId]));
    dispatch(getDataBaseCounts([ district_Id, blockId, villageId]));
  }, [filters]);

  function handleDownload(type) {
    const download_type = type

    // console.log("hello", download_type)

    // const download_source = "download_farmer_db"
    const district_Id =
    districtList.find((d) => d.value === filters.district)?.id || "all";
  const blockId =
    whichBlockList().find((b) => b.value === filters.block)?.id || "all";
  const villageId =
    whichVillageList().find((v) => v.value === filters.village)?.id || "all";

  console.log("new ones changed:", { district_Id, blockId, villageId });
    dispatch(getDataBaseDownload([source,district_Id, blockId, villageId, download_type]))
  }

  function whichBlockList() {
    if (
      filters.district === "all" ||
      !serverBlockList ||
      !serverBlockList.length
    )
      return uniq(blockList, "id");
    return serverBlockList.map((b) => ({
      name: b.block_name,
      value: b.block_name,
      id: b.block_id,
    }));
  }

  function whichVillageList() {
    if (
      filters.block === "all" ||
      !serverVillageList ||
      !serverVillageList.length
    )
      return uniq(villageList, "name");
    return serverVillageList.map((v) => ({
      name: v.village_name,
      value: v.village_name,
      id: v.village_id,
    }));
  }

  function handlePageChange(nextPage, total) {
    if (isDBLoading || nextPage === page) return;
    setPage(nextPage);
    dispatch(getDataBaseSource([source, nextPage, getDistrictId()]));
  }

  const CustomDataTable = (props) => {
    return (
      <DataTable
        highlightOnHover={true}
        pagination={true}
        customStyles={customStyles}
        fixedHeader
        fixedHeaderScrollHeight="60vh"
        noDataComponent={
          <EmptyState
            isLoading={isDBLoading}
            hasData={dataBase?.length}
            reset={resetFilters}
          />
        }
        striped
        {...props}
      />
    );
  };

  return (
    <>
      <Navbar active="database" />
      <div className="container mt-4">
        <Welcome />
        <TabGroup tabData={sources} callback={changeSource} active={source} />
        <div className="d-flex justify-content-between pt-4 align-items-center">
          <div>
            Last Updated: <strong>{lastUpdated()}</strong>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <span>Filter By</span>
              <img
                className="ms-1 me-2"
                src={filterIcon}
                alt="filter"
                style={{ height: "1.25em" }}
              />
            </div>
            {state_head_or_admin ? (
              <FilterDropdown
                callback={filterChanged}
                sort
                type="district"
                value={filters}
                defaultSelection="All Districts"
                data={uniq(districtList, "id")}
                isLoading={isDBLoading}
              />
            ) : (
              ""
            )}
            <FilterDropdown
              callback={filterChanged}
              sort
              type="block"
              value={filters}
              defaultSelection="All Blocks"
              data={whichBlockList()}
              isLoading={isDBLoading || isBlockListLoading}
            />
            <FilterDropdown
              callback={filterChanged}
              sort
              type="village"
              value={filters}
              defaultSelection="All Villages"
              data={whichVillageList()}
              isLoading={isDBLoading || isVillageListLoading}
            />
            <button
              className="btn btn-outline-secondary ms-1 btn-reset"
              onClick={resetFilters}
            >
              Reset
            </button>
            <div className="mx-1">
            <div className="dropdown">
              <button
                className="btn btn-success btn-project dropdown-toggle"
                type="button"
                id="downloadReportDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                &#x2B73; Download Report
              </button>
              <ul className="dropdown-menu" aria-labelledby="downloadReportDropdown">
                <li>
                  <button className="dropdown-item" onClick={() => handleDownload('excel')}>
                    Excel Report
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" onClick={() => handleDownload('pdf')}>
                    PDF Report
                  </button>
                </li>
              </ul>
            </div>
          </div> 
        </div>
         
        </div>
      </div>
      <div className="container my-4">
       
        {source === "farmer_db" ? (
          <CustomDataTable
            columns={dataBase_columns[source]}
            data={isDBLoading ? [] : dataBase?.data}
            paginationServer
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={handlePageChange}
            paginationTotalRows={counts?.farmer_count || "N/A"}
            paginationPerPage={100}
            paginationDefaultPage={page}
            fixedHeader
          />
        ) : (
          <CustomDataTable
            columns={dataBase_columns[source]}
            data={isDBLoading ? [] : filteredData(dataBase?.data)}
          />
        )}
      </div>
    </>
  );
};

export default Database;
