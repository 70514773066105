export const TimeFormat = (values) => {
  if (!values || typeof values !== "string") {
    // Handle the case where values is undefined or not a string
    console.error("Invalid input: Expected a non-empty string in HH:mm format.");
    return null; // or return a default value if needed
  }

  try {
    // Split the input time into hours and minutes
    const [hours, minutes] = values.split(':').map(Number);

    // Create a new Date object set to the local timezone
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    // Format the time to a 2-digit hour and minute format
    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return formattedTime;
  } catch (error) {
    console.error("Error formatting time:", error);
    return null; // or handle the error as needed
  }
};
