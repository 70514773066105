import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { summaryReportGet} from '../redux/slice/summaryReportSlice';
import {columns} from './columns.js';
import { TabGroup, CustomTable } from '../components';
// import CustomTable from './CustomTable';
import { format } from "date-fns";
import "./styles.css";

export { SummaryData };

function SummaryData(props) {
    const dispatch = useDispatch();
    const {district_filter, bubble, summary} = props;
    // let summary = useSelector((state) => state?.summaryReportSlice?.summary);
    const [values, setValues] = useState({
        "paddy_harvesting": [],
        "decomposer": [],
        "dsr_enforcement_measures": [],
        "achievement_iec_activities_under_crm": [],
        "area_covered_crm_machinery": [],
        "progress_regarding_incentive_insitu_exsitu": [],
        "utilization_in_industries": [],
    });
    const [section, setSection] = useState('paddy_harvesting');
    const formatDate = (d) => d.toISOString().split("T")[0];
    
    useEffect(() => {
        if (summary && Object.keys(summary).length) {
            let next = flattenSummary(summary);
            next = appendTotals(next);
            setValues(next);
        }
    }, [summary]);

    useEffect(() => {
        if (!values) return;
        let farmers = 0, area = 0, last_updated = 'N/A', latest;

        let decompValues = values.decomposer;
        if (district_filter && district_filter !== 'all' && !isNaN(parseInt(district_filter))) {
            decompValues = decompValues.filter(d => parseInt(d.district_id) === parseInt(district_filter));
        }
        if (decompValues.length) {
            let lastDV = decompValues.slice(-1)[0];
            farmers = lastDV?.farmers_benifitted || 0;
        }
        let paddyValues = values.paddy_harvesting;
        if (district_filter && district_filter !== 'all' && !isNaN(parseInt(district_filter))) {
            paddyValues = paddyValues.filter(d => parseInt(d.district_id) === parseInt(district_filter));
        }
        if (paddyValues.length) {
            let lastPV = paddyValues.slice(-1)[0];
            area = lastPV?.paddy_area_harvested_till_date_acres || 0;
        }

        let sectionValues = values[section].filter(d => d.district_id !== 'total');
        if (district_filter && district_filter !== 'all' && !isNaN(parseInt(district_filter))) {
            sectionValues = sectionValues.filter(d => parseInt(d.district_id) === parseInt(district_filter));
        }
        if (sectionValues.length) {
            let stamps = getAllTimestamps(sectionValues);
            if (stamps.length) {
                latest = Math.max(...stamps.map(s => new Date(s).getTime()))
                last_updated = format(new Date(latest), "dd/MM/yyyy");
            }
        }

        bubble({farmers, area, last_updated});
    }, [values, district_filter, section])

    function getAllTimestamps(obj) {
        const times = [];
        function traverse(obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === 'object') {
                        traverse(obj[key]);
                    } else if (key.indexOf('modified_on') > -1) {
                        times.push(obj[key]);
                    }
                }
            }
        }
        traverse(obj);
        return times;
    }

    function filteredData(data) {
        let nextData = data;
        if (district_filter && district_filter !== 'all' && !isNaN(parseInt(district_filter))) {
            nextData = nextData.filter(d => parseInt(d.district_id) === parseInt(district_filter));
        }
        return nextData;
    }
    
    const tabs = [
        {
            name: 'paddy_harvesting',
            label: 'Paddy Harvesting'
        },
        {
            name: 'decomposer',
            label: 'Decomposer'
        },
        {
            name: 'dsr_enforcement_measures',
            label: 'Enforcement'
        },
        {
            name: 'achievement_iec_activities_under_crm',
            label: 'IEC Activites',
            flatten: true
        },
        {
            name: 'area_covered_crm_machinery',
            label: 'CRM Machinery',
            flatten: true
        },
        {
            name: 'progress_regarding_incentive_insitu_exsitu',
            label: '1000/Acre Incentive',
            flatten: true
        },
        {
            name: 'utilization_in_industries',
            label: 'Industries'
        },
        {
            name: 'water_conservation_techniques',
            label: 'Water Conservation Techniques',
            flatten: true
        },
    ]

    function flattenSummary(data) {
        if (!data) return {};
        let newData = Object.assign({}, data);
        const needsFlattening = tabs.filter(t => t.flatten).map(t => t.name);
        Object.entries(newData).filter(([key, value]) => needsFlattening.includes(key)).forEach(([section, sectionData]) => {
            let newSectionData = Object.assign([], sectionData)
            newSectionData.forEach((s, i) => {
                newSectionData[i] = flatten(s)
            });
            Object.assign(newData, {[section]: newSectionData});
        });
        return newData;
    }

    function appendTotals(data) {
        if (!data) return {};
        let newData = Object.assign({}, data);
        // debugger;
        Object.entries(newData).forEach(([section, sectionData]) => {
            if (sectionData.slice(-1).district_id === 'total') return; 
            const total = {};
            Object.keys(sectionData[0]).forEach(k => total[k] = 0);
            sectionData.forEach(district => {
                Object.entries(district)
                    .filter(([key,value]) => !isNaN(parseFloat(value)))
                    .forEach(([key, value]) => total[key] += value);
            });
            let sectionDataWithTotal = Object.assign([],sectionData);
            Object.assign(total, {district_id: 'total', district_name: 'Total'})
            sectionDataWithTotal.push(total);
            Object.assign(newData, {[section]: sectionDataWithTotal})
        });
        console.log(data, newData);
        return newData;
    }

    function flatten(obj, parentKey = '') {
        const result = {};
        // debugger;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = parentKey ? `${parentKey}-${key}` : key;
                if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                    Object.assign(result, flatten(obj[key], newKey));
                } else {
                    result[newKey] = obj[key];
                }
            }
        }
        return result;
    }

    function updateSection(next) {
        if (next === section) return;
        setSection(next.name);
    }

    return (
        <div>
            <TabGroup tabData={tabs} callback={updateSection} active={section} />
            <div className="my-4 custom-table-container">
                <CustomTable id="summary_table" data={filteredData(values[section])} columns={columns[section]} />
            </div>
        </div>
    )
}